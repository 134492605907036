import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import {CSVLink} from 'react-csv';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const SpecGenReport = () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [jobCardOptions, setJobCardOptions] = useState([]);
  const [pocOptions, setPocOptions] = useState([]);
  const [labels, setLabels] = useState({
    customer: '',
    sort: [],
    jobCard: [],
    poc: [],
    shadeString: '',
    blend: {
      blend1: {
        name: '',
        percent: '',
      },
      blend2: {
        name: '',
        percent: '',
      },
      blend3: {
        name: '',
        percent: '',
      },
    },
    finishRequired: [],
    sortCode: '',
    finishedWidth: '',
    type: '',
    processRequired: [],
  });
  const [apiResponse, setApiResponse] = useState([]);
  const [currSpecHeadData, setCurrSpecHeadData] = useState({
    customer: { name: '', id: '' },
    poc: { id: '' },
    sort: { sortName: '', id: '' },
  });
  const [itemData, setItemData] = useState();
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pdfData, setPdfData] = useState({
    tableTitles: [],
    tableContents: [],
    label: {},
  });
  const [showDownload, setShowDownload] = useState(false);
  const optionsSetter = (response: any, customer = true, sort = true, jobCard = true, poc = true) => {
    const currJobCardOptions: any = [];
    const currCustomerOptions: any = [];
    const currCustSortOptions: any = [];
    const currPocOptions: any = [];
    response.forEach((t: any) => {
      t = t?.attributes?.jobCard.data;
      let temJbOpt = {
        label: `${t?.id}-${t?.attributes?.idLotNum}`,
        value: `${t?.id}`,
      };
      let temCustOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.id}`,
      };
      let temSortOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id}`,
      };
      let temPocOptions = {
        label: `${t?.attributes?.processOrder?.data?.id}`,
        value: `${t?.attributes?.processOrder?.data?.id}`,
      };
      if (temJbOpt.label !== 'undefined') {
        currJobCardOptions.push(temJbOpt);
      }
      if (temSortOpt.label !== 'undefined') {
        currCustSortOptions.push(temSortOpt);
      }
      if (temCustOpt.label !== 'undefined') {
        currCustomerOptions.push(temCustOpt);
      }
      if (temPocOptions.label !== 'undefined') {
        currPocOptions.push(temPocOptions);
      }
    });
    if (jobCard) {
      setJobCardOptions(arrUnique(currJobCardOptions));
    }
    if (customer) {
      setCustomerOptions(arrUnique(currCustomerOptions));
    }
    if (poc) {
      setPocOptions(arrUnique(currPocOptions));
    }
    if (sort) {
      setSortOptions(arrUnique(currCustSortOptions));
    }
  };
  const getApi = () => {
    axios
      .get(
        `${baseUri()}packing-slips?sort[0]=jobCard.id:desc&fields[0]=dispatch&fields[1]=cotton&fields[2]=mmf&populate[jobCard][fields][0]=idLotNum&populate[jobCard][populate][processOrder][fields][0]=id&populate[jobCard][populate][processOrder][fields][1]=shade&populate[jobCard][populate][processOrder][fields][3]=finishWidth&populate[jobCard][populate][processOrder][fields][4]=finishRequired&populate[jobCard][populate][processOrder][fields][5]=pocFinish&populate[jobCard][populate][processOrder][fields][2]=pocType&populate[jobCard][populate][processOrder][fields][4]=processRequired&populate[jobCard][populate][processOrder][populate][customersSort][populate][user][fields][0]=name&populate[jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName&populate[jobCard][populate][processOrder][populate][customersSort][populate][sort]=*&filters[jobCard][status][$eq]=open`,
      )
      .then((response) => {
        response = response.data.data;
        setApiResponse(response);
        optionsSetter(response);
        hideLoader();
      });
  };
  const arrUnique = (arr) => {
    const map = new Map();
    arr.forEach((itm: any) => {
      map.set(JSON.stringify(itm), itm);
    });
    return [...map.values()];
  };
  const customerFilterOptions = (labelValue = labels.customer.label) => {
    let filteredApiResponse = apiResponse.filter(
      (t: any) =>
        t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user
          ?.data?.attributes?.name === labelValue,
    );
    return filteredApiResponse;
  };
  const sortFilterOptions = (currLabel = labels.sort) => {
    let filteredApiResponse = apiResponse.filter(
      (t: any) =>
        t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes
          ?.sortName === currLabel.label,
    );
    return filteredApiResponse;
  };
  const pocFilterOptions = (currLabel = labels.poc) => {
    let filteredApiResponse = apiResponse.filter((t: any) => {
      let isFiltered = false;
      currLabel.forEach((label) => {
        if (+label.label === +t?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.id) {
          isFiltered = true;
        }
      });
      return isFiltered;
    });
    return filteredApiResponse;
  };
  const jobCardFilterChange = (label = labels) => {
    if (label.jobCard.length === 0) {
      optionsSetter(apiResponse, false);
    }
    let filteredApiResponse = [];
    let custMap = new Map();
    label.jobCard.forEach((currLabel) => {
      let currArr = apiResponse.filter((t: any) => {
        if (+t?.attributes?.jobCard?.data?.id === +currLabel.value) {
          custMap.set(
            t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes
              ?.user?.data?.attributes?.name,
            {
              customer: {
                label:
                  t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                    ?.attributes?.user?.data?.attributes?.name,
                value:
                  t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                    ?.attributes?.user?.data?.id,
              },
              sort: {
                label:
                  t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                    ?.attributes?.sortName,
                value: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id,
              },
              poc: [
                {
                  label: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.id,
                  value: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.id,
                },
              ],
              shadeString: `${t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.pocType}-${t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade}`,
              blend: {
                blend1: {
                  name: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                    ?.attributes?.sort?.data?.attributes?.blend1,
                  percent:
                    t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                      ?.attributes?.sort?.data?.attributes?.blendPercent1,
                },
                blend2: {
                  name: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                    ?.attributes?.sort?.data?.attributes?.blend2,
                  percent:
                    t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                      ?.attributes?.sort?.data?.attributes?.blendPercent2,
                },
                blend3: {
                  name: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                    ?.attributes?.sort?.data?.attributes?.blend3,
                  percent:
                    t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data
                      ?.attributes?.sort?.data?.attributes?.blendPercent3,
                },
              },
              finishRequired: [
                ...[
                  ...t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.finishRequired.filter(
                    (a) => a.checked && a.name !== 'Others',
                  ),
                ].map((b) => b.name),
                t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.pocFinish,
              ],
              sortCode:
                t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes
                  ?.sort?.data?.attributes?.sortCode,
              finishedWidth: t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.finishWidth,
              type: t.attributes.cotton === true ? 'cotton' : 'mmf',
              processRequired: [
                ...t.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.processRequired.filter(
                  (a) => a.checked,
                ),
              ].map((a) => a.name),
            },
          );
        }
        return +t?.attributes?.jobCard?.data?.id === +currLabel.value;
      });
      filteredApiResponse.push(currArr);
    });
    filteredApiResponse = filteredApiResponse.flat();
    optionsSetter(filteredApiResponse, false, true, false, true);
    const customersVal = [...custMap.values()];
    if (customersVal.length === 1 && customersVal[0].customer.label !== undefined) {
      setLabels({ ...label, ...customersVal[0] });
    } else {
      setLabels({
        ...label,
        customer: '',
        sort: '',
        poc: [],
        shadeString: '',
        blend: {
          blend1: {
            name: '',
            percent: '',
          },
          blend2: {
            name: '',
            percent: '',
          },
          blend3: {
            name: '',
            percent: '',
          },
        },
        finishRequired: [],
        sortCode: '',
        finishedWidth: '',
        type: '',
        processRequired: [],
      });
    }
  };
  const localeNumStr = (num) => {
    let temp: any = +num;
    temp = temp.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return temp;
  };
  const specGenReportGetter = async () => {
    let res = await axios.get(
      `${baseUri()}grey-receipt-advice/spec-gen-report?poc=${labels.poc.map((t) => +t.label).join(',')}`,
    );
    res = await res.data.data;
    const currTableTitles = [
      'S.No',
      'Role No',
      'Bale No.',
      'Date',
      'P.Pk',
      'L Mts',
      'Nt Weight',
      'Gr Weight',
      'Category',
      'ID Lot Num',
    ];
    const categoryTotal = {
      total: {
        'P.Pk': 0,
        'L Mts': 0,
        'Nt Weight': 0,
        'Gr Weight': 0,
      },
    };
    const currTableBody: any = [];
    res.result.forEach((t, i) => {
      const temp = [
        i + 1,
        t.role_num,
        t.bale_num,
        new Date(t.date).toLocaleDateString('en-GB'),
        t.pieces_packed,
        localeNumStr(t.bale_total),
        localeNumStr(t.nt_weight),
        localeNumStr(t.gr_weight),
        t.category,
        t.id_lot_num,
      ];
      if (categoryTotal[t.category]) {
        categoryTotal[t.category]['P.Pk'] += 1;
        categoryTotal[t.category]['L Mts'] += +t.bale_total;
        categoryTotal[t.category]['Nt Weight'] += +t.nt_weight;
        categoryTotal[t.category]['Gr Weight'] += +t.gr_weight;
      } else {
        categoryTotal[t.category] = {
          'P.Pk': 1,
          'L Mts': +t.bale_total,
          'Nt Weight': +t.nt_weight,
          'Gr Weight': +t.gr_weight,
        };
      }
      categoryTotal.total['P.Pk'] += 1;
      categoryTotal.total['L Mts'] += +t.bale_total;
      categoryTotal.total['Nt Weight'] += +t.nt_weight;
      categoryTotal.total['Gr Weight'] += +t.gr_weight;
      currTableBody.push(temp);
    });
    currTableBody.push(['', '', '', '', '', '', '', '', '', '']);
    for (let t in categoryTotal) {
      if (t !== 'total') {
        currTableBody.push([
          '',
          '',
          '',
          t,
          categoryTotal[t]['P.Pk'],
          localeNumStr(categoryTotal[t]['L Mts']),
          localeNumStr(categoryTotal[t]['Nt Weight']),
          localeNumStr(categoryTotal[t]['Gr Weight']),
          '',
          '',
        ]);
      }
    }
    currTableBody.push([
      '',
      '',
      '',
      'Total',
      categoryTotal.total['P.Pk'],
      localeNumStr(categoryTotal.total['L Mts']),
      localeNumStr(categoryTotal.total['Nt Weight']),
      localeNumStr(categoryTotal.total['Gr Weight']),
      '',
      '',
    ]);
    setPdfData({
      label: labels,
      tableTitles: currTableTitles,
      tableContents: currTableBody,
    });
    setTableData(res.result);
    setItemData(res.result);
  };
  const columns = [
    {
      name: 'Role Num',
      selector: (row) => `${row.role_num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bale Num',
      selector: (row) => `${row.bale_num}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Date',
      selector: (row) => `${new Date(row.date).toLocaleDateString('en-GB')}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Pieces Packed',
      selector: (row) => `${row.pieces_packed}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'L Meters',
      selector: (row) => `${row.bale_total}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'NT Weight',
      selector: (row) => `${row.nt_weight}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'GR Weight',
      selector: (row) => `${row.gr_weight}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Category',
      selector: (row) => `${row.category}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'ID Lot Num',
      selector: (row) => `${row.id_lot_num}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'bale_num',
    'bale_total',
    'category',
    'date',
    'gr_weight',
    'id_lot_num',
    'nt_weight',
    'pieces_packed',
    'role_num',
  ];
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const resetForm = () => {
    setLabels({
      customer: '',
      jobCard: [],
      poc: '',
      sort: '',
    });
    setTimeout(() => {
      optionsSetter(apiResponse);
    }, 500);
  };

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);
  useEffect(() => {
    showLoader();
    getApi();
  }, []);
  return (
    <>
      <SEO title="Spec Gen / Dispatch" />
      <Col breakPoint={{ xs: 12, md: 12 }}>
        <Card>
          <header> Spec Gen Report</header>
          <CardBody className="cardbody">
            <Row>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer</label>
                <SelectField
                  fullWidth
                  options={customerOptions}
                  id="customer"
                  name="customer"
                  placeholder="Select Customer"
                  value={labels.customer}
                  onChange={(e) => {
                    setLabels({
                      ...labels,
                      poc: [],
                      sort: '',
                      customer: {
                        label: e.label,
                        value: e.value,
                      },
                      jobCard: [],
                      shadeString: '',
                      blend: {
                        blend1: {
                          name: '',
                          percent: '',
                        },
                        blend2: {
                          name: '',
                          percent: '',
                        },
                        blend3: {
                          name: '',
                          percent: '',
                        },
                      },
                      finishRequired: [],
                      sortCode: '',
                      finishedWidth: '',
                      type: '',
                      processRequired: [],
                    });
                    optionsSetter(customerFilterOptions(e.label), false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer Sort</label>
                <SelectField
                  fullWidth
                  options={sortOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Customer Sort"
                  value={labels.sort}
                  onChange={(e) => {
                    const currFilteredSortOptions = sortFilterOptions(e);
                    setLabels({
                      ...labels,
                      sort: e,
                      customer: {
                        label:
                          currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name,
                        value:
                          currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.user?.data?.id,
                      },
                      poc: [
                        {
                          label:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.id,
                          value:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.id,
                        },
                      ],
                      shadeString: `${currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade}`,
                      blend: {
                        blend1: {
                          name: currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend1,
                          percent:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent1,
                        },
                        blend2: {
                          name: currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend2,
                          percent:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent2,
                        },
                        blend3: {
                          name: currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend3,
                          percent:
                            currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent3,
                        },
                      },
                      finishRequired: [
                        ...[
                          ...currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.finishRequired.filter(
                            (t) => t.checked && t.name !== 'Others',
                          ),
                        ].map((t) => t.name),
                        currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes
                          ?.pocFinish,
                      ],
                      sortCode:
                        currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes
                          ?.customersSort?.data?.attributes?.sort?.data?.attributes?.sortCode,
                      finishedWidth:
                        currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes
                          ?.finishWidth,
                      type: currFilteredSortOptions[0].attributes.cotton === true ? 'cotton' : 'mmf',
                      processRequired: [
                        ...currFilteredSortOptions[0].attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.processRequired.filter(
                          (t) => t.checked,
                        ),
                      ].map((t) => t.name),
                    });
                    optionsSetter(currFilteredSortOptions, false, false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Job Card / Lot Number</label>
                <SelectField
                  fullWidth
                  options={jobCardOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Job Card - Lot Number"
                  value={labels.jobCard}
                  onChange={(e) => {
                    setLabels({ ...labels, jobCard: e });
                    jobCardFilterChange({ ...labels, jobCard: e });
                  }}
                  isMulti
                  multiple
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>POC</label>
                <SelectField
                  fullWidth
                  isMulti
                  options={pocOptions}
                  onChange={(e) => {
                    const currFilteredPOCOptions = pocFilterOptions(e);
                    if (currFilteredPOCOptions.length > 0) {
                      setLabels({
                        ...labels,
                        poc: e,
                        jobCard: [],
                        sort: {
                          label:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sortName,
                          value:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.id,
                        },
                        customer: {
                          label:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name,
                          value:
                            currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.user?.data?.id,
                        },
                        shadeString: `${currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.shade}`,
                        blend: {
                          blend1: {
                            name: currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend1,
                            percent:
                              currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                                ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent1,
                          },
                          blend2: {
                            name: currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend2,
                            percent:
                              currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                                ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent2,
                          },
                          blend3: {
                            name: currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                              ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend3,
                            percent:
                              currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                                ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent3,
                          },
                        },
                        finishRequired: [
                          ...[
                            ...currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.finishRequired.filter(
                              (t) => t.checked && t.name !== 'Others',
                            ),
                          ].map((t) => t.name),
                          currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.pocFinish,
                        ],
                        sortCode:
                          currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.sortCode,
                        finishedWidth:
                          currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data
                            ?.attributes?.finishWidth,
                        type: currFilteredPOCOptions[0]?.attributes.cotton === true ? 'cotton' : 'mmf',
                        processRequired: [
                          ...currFilteredPOCOptions[0]?.attributes?.jobCard?.data?.attributes?.processOrder?.data?.attributes?.processRequired.filter(
                            (a) => a.checked,
                          ),
                        ].map((a) => a.name),
                      });
                      optionsSetter(currFilteredPOCOptions, false, true, true, false);
                    } else {
                      resetForm();
                    }
                  }}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select POC"
                  value={labels.poc}
                  className="pull-down multi-select"
                />
              </Col>
              {/* <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {labels.shadeString}
                </span>
              </Col> */}
              <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Button
                  status="Success"
                  type="button"
                  className="btnrad"
                  disabled={labels.poc.length === 0}
                  halfWidth
                  onClick={() => {
                    specGenReportGetter();
                    // getPackingSlips();
                  }}
                >
                  Get Report
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="button"
                  className="btnrad"
                  shape=""
                  halfWidth
                  onClick={() => {
                    setLabels({
                      customer: '',
                      jobCard: [],
                      poc: '',
                      sort: '',
                    });
                    setTimeout(() => {
                      optionsSetter(apiResponse);
                    }, 500);
                  }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <header>Spec Gen Report</header>
          <CardBody>
            {showDownload && (
              <p>
                <PdfGenerator data={pdfData} documentTitle="spec-gen-report" />
                {' '}
                <CSVLink data={tableData}>Download Excel</CSVLink>
              </p>
            )}
            <Input fullWidth size="Small">
              <input
                type="text"
                onChange={(e) => filterChange(e.target.value)}
                placeholder="Filter"
                name="tableFilter"
                value={tableFilter}
              />
            </Input>
            <DataTable
              columns={columns}
              data={tableData}
              fixedHeader
              allowOverflow={false}
              overflowY={false}
              overflowX={false}
              wrap={false}
              highlightOnHover={true}
              pagination={true}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SpecGenReport;
